import styled from 'styled-components';

const size = {
  xs: '320px',
sm: '768px',
lg: '1200px',
};
const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`,
};

export const FormBlock = styled.div`
    max-width: 500px;
    background: #ffffff;
    font-size: 13px;
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    border-radius: 2px;
     @media only screen and ${device.xs}{
            padding: 15px;
    }
    @media only screen and ${device.sm}{
       padding: 15px;
    }
    @media only screen and ${device.lg}{
                 padding: 30px;
    }

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const FooterBlock = styled.div`
  border-top: 1px solid #e7e7e7;
  padding-top: 1rem;
`;

export const LinkButton = styled.span`
  background: none;
  border: none;
  cursor: pointer;
`;
