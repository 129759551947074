import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Customer, getCustomer } from '../services/api.service';

interface CustomerContextValue {
  customer: Customer | null;
  loading: boolean;
  error: string;
}

type Props = {
  children: ReactNode;
}

const CustomerContext = createContext<CustomerContextValue | undefined>(undefined);

const extractCustomerIdFromSubdomain = () => {
  const { hostname } = window.location;
  return hostname.split('.')[0];
};

const CustomerProvider = ({ children }: Props) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const customerId = extractCustomerIdFromSubdomain();

  const fetchCustomer = useCallback(async () => {
    setLoading(true);
    try {
      const { customer: customerResponse } = await getCustomer({ clientId: customerId });
      setCustomer(customerResponse);
    } catch (error) {
      const message = (error as Error).message;
      setError(message);
    } finally {
      setLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  return (
    <CustomerContext.Provider value={{ customer, loading, error }}>
      {children}
    </CustomerContext.Provider>
  );
};

const useCustomer = (): CustomerContextValue => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomer must be used within a CustomerProvider');
  }
  return context;
};

export { CustomerProvider, useCustomer };
