import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './root.css';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import i18n from './services/i18n';
import { I18nextProvider } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import LayoutV2 from './containers/layout2';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import { CustomerProvider } from './hooks/useCustomer';

const GlobalStyle = createGlobalStyle`
    .alert-dismissible .close {
        padding: 0.63rem 1.25rem;
    }
`;

const container = document.getElementById('root');

const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutV2 />,
    children: [
      {
        index: true,
        path: "/",
        lazy: () => import("./pages/Home").then(module => ({ Component: module.default })),
      },
      {
        path: "about",
        lazy: () => import("./pages/About").then(module => ({ Component: module.default })),
      },
      {
        path: "projects",
        lazy: () => import("./pages/Projects").then(module => ({ Component: module.default })),
      },
      {
        path: "projects/:id",
        lazy: () => import("./pages/Project/Project").then(module => ({ Component: module.default })),
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <CustomerProvider>
      <I18nextProvider i18n={i18n}>
          <GlobalStyle/>
          <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            position={'top-left'}
          />
          <App />
      </I18nextProvider>
      </CustomerProvider>
    </React.StrictMode>,
  );
} else {
  console.error('Root container not found');
}
