import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = () => {
  const {t} = useTranslation()
  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <Link to="/">{t('home')}</Link>
      </BreadcrumbItem>
      {pathNames.map((value, index) => {
        const to = `/${pathNames.slice(0, index + 1).join('/')}`;

        return (
          <BreadcrumbItem key={to}>
            {index === pathNames.length - 1 ? (
              t(value)
            ) : (
              <Link to={to}>{t(value)}</Link>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
