import React from 'react';
import styled from 'styled-components';
import { ListInlineItem } from 'reactstrap';
import { List } from 'reactstrap';

const Block = styled.div`
  img {
     height: 40px;
  }
  text-align: center;
`;

function PaymentLogos(): any {
  return (
    <Block>
      <List type="inline">
        <ListInlineItem><img src={'/payments/visa.svg'} alt={'visa'}/></ListInlineItem>
        <ListInlineItem><img src={'/payments/mastercard.svg'} alt={'mastercard'}/></ListInlineItem>
        <ListInlineItem><img src={'/payments/amex.svg'} alt={'amex'}/></ListInlineItem>
        <ListInlineItem><img src={'/payments/maestro.svg'} alt={'maestro'}/></ListInlineItem>
      </List>
    </Block>
  );
}

export default PaymentLogos;
