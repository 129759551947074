import React, { useState } from 'react';
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCustomer } from '../../hooks/useCustomer';

const Navigation = () => {

  const { i18n, t } = useTranslation();

  const { customer } = useCustomer();
  const [activeLang, setActiveLang] = useState(i18n.language);

  const changeLanguage = (lang: string) => {
    (async () => {
      await i18n.changeLanguage(lang, () => {
        setActiveLang(lang);
      });
    })();
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleNav = () => setIsOpen(!isOpen);

  const languageMap: { [key: string]: { code: string; name: string; flag: string } } = {
    hy: { code: 'hy', name: 'Հայերեն', flag: '/flags/am.svg' },
    en: { code: 'en', name: 'English', flag: '/flags/us.svg' },
    fr: { code: 'fr', name: 'Français', flag: '/flags/fr.svg' },
    ru: { code: 'ru', name: 'Русский', flag: '/flags/ru.svg' },
  };

  return <>
    <Navbar className={'mb-3 bg-body-tertiary'} expand={'lg'} container>
      <NavbarBrand tag={RouterNavLink} to="/"><img
        alt="logo"
        src={customer?.attributes.logoPath}
        style={{
          height: 30,
        }}
      /></NavbarBrand>
      <NavbarToggler onClick={toggleNav}/>
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto" navbar>
          <NavItem>
            <NavLink tag={RouterNavLink} to="/projects">{t('projects')}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RouterNavLink} to="/about">{t('about')}</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret={false}>
              <img width={25} height={25} src={languageMap[activeLang].flag} alt={languageMap[activeLang].code}/>
            </DropdownToggle>
            <DropdownMenu end>
              {Object.keys(languageMap).map((lang) => (
                <DropdownItem
                  key={languageMap[lang].code}
                  onClick={() => changeLanguage(languageMap[lang].code)}
                >
                  <img width={20} height={20} src={languageMap[lang].flag} alt={languageMap[lang].code}/> {languageMap[lang].name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  </>;
};

export default Navigation;
