import React from 'react';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Navigation from '../components/Navigation/Navigation';
import { useCustomer } from '../hooks/useCustomer';
import Breadcrumbs from '../components/Breadcrumbs';
import { Container } from 'reactstrap';

function LayoutV2(): any {
  const { t } = useTranslation();

  const { customer, loading } = useCustomer();

  return (
    <>
      {loading && <div>{t('loading')}</div>}
      {!loading && customer && <>
        <header>
          <Navigation/>
        </header>
        <main className={"mb-3"}>
          <Container>
            <Breadcrumbs/>
          </Container>
          <Outlet/>
        </main>
        <footer>
          <Footer/>
        </footer>
      </>}
    </>
  );
}

export default LayoutV2;
