import React from 'react';
import styled from 'styled-components';
import { COMPANY_NAME } from '../helpers/constants.helper';
import { Customer } from '../services/api.service';
import { useTranslation } from 'react-i18next';

const Block = styled.div`
  text-align: center;
  margin: 20px 0;
  cursor: default;
  user-select: none;
`;
const Rights = styled.div`
  font-size: 13px;

`;
const Credits = styled.div`
  font-size: 11px;
  color: #858585;
`;

const Copyright = (props: { customer?: Customer | null }) => {

  const {t} = useTranslation();
  const year = new Date().getFullYear();

  const companyName: string = props.customer ? props.customer?.attributes?.name : COMPANY_NAME;

  return (
    <Block>
      <Rights>
        © {year} <b>{companyName}</b>։ {t('allRightsReserved')}
      </Rights>
      <Credits>
        {t('powered')}
      </Credits>
    </Block>
  );
};

export default Copyright;
