import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import { COMPANY_NAME } from '../helpers/constants.helper';

const resources = {
  ru: {
    translation: {
      amount: 'Сумма',
      transaction: {
        succeed: 'Транзакция успешно выполнена.',
        failed: 'Запрос отклонён.',
      },
      firstName: 'Имя',
      lastName: 'Фамилия',
      email: 'Почта',
      notes: 'Заметки',
      other: 'Другое',
      submit: 'Подтвердить',
      close: 'Закрыть',
      loading: 'Загрузка ․․․',
      notFound: 'По следующей ссылке ничего не найдено.',
      unsubscribe: {
        succeed: 'Вы успешно отменили подписку.',
        failed: 'Произошла ошибка. Пожалуйста, попробуйте немного позже.',
        confirm: 'Пожалуйста, подтвердите отмену подписки.',
      },
      invalidRequest: 'Неправильный запрос.',
      read: `Читать`,
      agreeTermsAndConditions: `Согласен с правилами и условиями.`,
      allRightsReserved: `Все права защищены`,
      powered: `Сделано с любовью компанией ${COMPANY_NAME}.`,
      oneTime: 'Одноразовый',
      monthly: 'Ежемесячный',
      chooseInitiative: 'Выберите инициативу',
      select: 'Выбирать',
      about: 'О нас',
      projects: 'Проекты',
      donate: 'Пожертвовать',
      home: "Главная страница",
      contributions: "пожертвование",
      goal: "Цель",
      highlights: "Основные",
      description: "Описание",
      updates: "Обновления",
      reports: "Отчеты",
      donateNow: "Пожертвовать сейчас",
      seeTop: "Топ",
      seeAll: "Все",
      top: "топ",
      recent: "недавний",
      first: "первый",
    },
  },
  fr: {
    translation: {
      amount: 'Le montant',
      transaction: {
        succeed: 'Le transfert a été effectué avec succès.',
        failed: `L'accord a été rejeté.`,
      },
      firstName: 'Prénom',
      lastName: 'Nom',
      email: 'E-mail Adresse:',
      notes: 'Notes',
      other: 'Autre',
      submit: 'Confirmer',
      close: 'Fermer',
      loading: 'Chargement ...',
      notFound: `Rien n'a été trouvé avec le lien suivant.`,
      unsubscribe: {
        succeed: 'Vous avez été désabonné avec succès',
        failed: `Une erreur s'est produite. Veuillez réessayer plus tard.`,
        confirm: 'Veuillez confirmer votre désinscription.',
      },
      invalidRequest: 'Requête invalide.',
      read: `Lire`,
      agreeTermsAndConditions: `J'accepte les règles et conditions.`,
      allRightsReserved: `Tous les droits sont réservés.`,
      powered: `Créé avec amour par ${COMPANY_NAME}.`,
      oneTime: 'Transfert unique',
      monthly: 'Mensuel',
      chooseInitiative: "Choisissez l'initiative",
      select: "Sélectionner",
      about: "À propos de nous",
      projects: "Projets",
      donate: "Faire un don",
      home: "Accueil",
      contributions: "la donation",
      goal: "objectif",
      highlights: "faits saillants",
      description: "La description",
      updates: "Mises à jour",
      reports: "Rapports",
      donateNow: "Faire un don maintenant",
      seeTop: "Voir le top",
      seeAll: "Voir tout",
      top: "le top",
      recent: "récemment",
      first: "premier",
    },
  },
  hy: {
    translation: {
      amount: 'Գումար',
      transaction: {
        succeed: 'Փոխանցումը հաջողությամբ կատարված է։',
        failed: 'Գործարքը մերժված է։',
      },
      firstName: 'Անուն',
      lastName: 'Ազգանուն',
      email: 'Էլ․ հասցե',
      notes: 'Նշումներ',
      other: 'Այլ',
      submit: 'Հաստատել',
      close: 'Փակել',
      loading: 'Բեռնվում է ․․․',
      notFound: 'Հետևյալ հղմամբ ոչինչ չի գտնվել։',
      unsubscribe: {
        succeed: 'Դուք հաջողությամբ ապաբաժանորդագրվեցիք։',
        failed: 'Տեղի է ունեցել սխալ։ Խնդրում ենք փորձել մի փոքր ուշ։',
        confirm: 'Խնդրում ենք հաստատել Ձեր ապաբաժանորդագրությունը։',
      },
      invalidRequest: 'Սխալ հարցում։',
      read: `Կարդալ`,
      agreeTermsAndConditions: `Համաձայն եմ կանոններին և պայմաններին։`,
      allRightsReserved: `Բոլոր իրավունքները պաշտպանված են։`,
      powered: `Պատրաստված է սիրով ${COMPANY_NAME} կազմակերպության կողմից։`,
      oneTime: 'Միանվագ',
      monthly: 'Ամսական',
      chooseInitiative: 'Ընտրեք նախաձեռնությունը',
      select: 'Ընտրել',
      about: 'Մեր մասին',
      projects: 'Նախագծեր',
      donate: 'Նվիրաբերել',
      home: "Գլխավոր էջ",
      contributions: "նվիրաբերություններ",
      goal: "նպատակ",
      highlights: "կարևոր",
      description: "Նկարագրություն",
      updates: "Թարմացումներ",
      reports: "հաշվետվություն",
      donateNow: "Նվիրաբերեք հիմա",
      seeTop: "Թոփ",
      seeAll: "Բոլորը",
      top: "ամենամեծը",
      recent: "վերջինը",
      first: "առաջինը",
    },
  },
  en: {
    translation: {
      amount: 'Amount',
      transaction: {
        succeed: 'Transfer completed successfully.',
        failed: 'Transfer declined.',
      },
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      notes: 'Notes',
      other: 'Other',
      submit: 'Submit',
      close: 'Close',
      loading: 'Loading ․․․',
      notFound: 'Requested page not found.',
      unsubscribe: {
        succeed: 'You have successfully unsubscribed.',
        failed: 'Something went wrong, please try later.',
        confirm: 'Please confirm your unsubscribe.',
      },
      invalidRequest: 'Invalid request',
      read: `Read`,
      agreeTermsAndConditions: `I agree Terms and Conditions.`,
      allRightsReserved: `All rights reserved.`,
      powered: `Made with love by ${COMPANY_NAME}.`,
      oneTime: 'One-time',
      monthly: 'Monthly',
      chooseInitiative: 'Choose the initiative',
      select: 'Select',
      about: 'About Us',
      projects: 'Projects',
      donate: 'Donate',
      home: "Home",
      contributions: "contributions",
      goal: "goal",
      highlights: "highlights",
      description: "Description",
      updates: "Updates",
      reports: "Reports",
      donateNow: "Donate Now",
      seeTop: "See top",
      seeAll: "See all",
      top: "the top",
      recent: "recent",
      first: "first"
    },
  },
};

const i18nInstance = i18n
  .use(LanguageDetector)
  .use(initReactI18next);

i18nInstance.init({
  resources,
  fallbackLng: 'hy',
  supportedLngs: ['hy', 'en', 'ru', 'fr'],
}).then(()=>{
  console.log('i18n initialized');
})

export default i18n;
