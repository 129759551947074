export const COMPANY_NAME = 'HyeCloud.dev';

export const TRANSACTION_TYPE_ONE_TIME = 2;
export const TRANSACTION_TYPE_PERIODIC = 1;

export interface TransactionType {
  label: string;
  value: number;
}
export const TRANSACTION_TYPES: TransactionType[] = [
  {
    label: 'oneTime',
    value: TRANSACTION_TYPE_ONE_TIME,
  },
  {
    label: 'monthly',
    value: TRANSACTION_TYPE_PERIODIC,
  },
];

export const VALIDATION_MESSAGES = {
  REQUIRED: 'Պարտադիր է լրացնել։',
  INVALID_EMAIL: 'Սխալ էլ․ հասցե։',
  MUST_ACCEPT_TERMS_AND_CONDITIONS: 'Պարտադիր է կարդալ և համաձայնվել։',
  MUST_BE_MIN_VALUE: 'Գումարի չափը պետք է գերազանցի 100 դրամը',
};
