import axios, { AxiosResponse } from 'axios';

const apiBase = axios.create({
  baseURL: '/api'
});

export interface Initiative {
  initiativeId: string
  label: string
  name: InitiativeName
}

 type InitiativeName = {
  [key: string] : string
 };

export interface Attributes {
  name: string;
  intro: string;
  description: any;
  privacyPolicy: any;
  logoPath: string;
  moneySizes: string;
  isOneTimePayment: 'false' | 'true';
  isMoneySizesEnabled: 'false' | 'true';
  isInitiativesEnabled: 'false' | 'true';
  initiatives: string;
}

export interface Customer {
  name: string;
  clientId: string;
  isActive: boolean;
  attributes: Attributes;
}

export interface Alert {
  isTransactionSucceed: boolean;
  message: string;
  isTouched: boolean;
}

export const getCustomer = async ({ clientId }: { clientId: string }): Promise<{
  customer: Customer
}> => {
  const response: AxiosResponse = await apiBase.get(`/api/v2/customers/${clientId}`);
  if (response.data?.error) {
    throw new Error(response.data.message);
  }
  return {
    customer: response.data?.result
  };
};

export interface AuthorizeParams {
  clientId: string;
  firstName: string;
  lastName: string;
  notes: string;
  amount: number;
  email: string;
  transactionTypeId: number;
  nextUrl: string;
}
export const authorizeTransaction = async (authorizeParams: AuthorizeParams): Promise <Customer> => {
  const response: AxiosResponse = await apiBase.post(`/api/v2/payments/authorize`, {
    ...authorizeParams,
  });
  if (response.data?.error) {
    throw new Error(response.data.message);
  }
  return response.data?.result;
};
export const getAlert = async (sessionId: string): Promise <{
  isTransactionSucceed: boolean,
  message: string,
}> => {
  const response: AxiosResponse = await apiBase.get(`/api/v2/sessions/${sessionId}`);
  if (response.data?.error) {
    throw new Error(response.data.message);
  }
  return response.data?.result;
};

export const checkUnsubscribeToken = async ({ unsubscribeToken, clientId }: any): Promise<Customer> => {
  const response: AxiosResponse = await apiBase.get(`/api/v2/payments/unsubscribe`, {
    params: {
      client_id: clientId,
      unsubscribe_token: unsubscribeToken,
    },
  });
  if (response.data?.error) {
    throw new Error(response.data.message);
  }
  return response.data?.result;
};
export const unsubscribe = async ({ unsubscribeToken, clientId }: any): Promise<Customer> => {
  const response: AxiosResponse = await apiBase.post(`/api/v2/payments/unsubscribe`, {
    clientId,
    unsubscribeToken,
  });
  if (response.data?.error) {
    throw new Error(response.data.message);
  }
  return response.data?.result;
};
